interface IWorkCard {
    title: string,
    content: string
}

export const WorkCardData: IWorkCard[] = [
    {
        title: "Apply",
        content: "Apply for the Airgram affiliate program and get a unique referral link."
    },
    {
        title: "Promote",
        content: "Promote Airgram on your website, social media, etc. using the affiliate link."
    },
    {
        title: "Earn money",
        content: "Earn recurring revenue for referred customers that make valid apurchases."
    }
]