import React from 'react';
import { Box } from '@chakra-ui/react';

const iframeWidth = {
  base: '342px',
  sm: '496px',
  md: '640px',
  lg: '720px',
  xl: '880px',
  '2xl': '960px',
};

const iframeHeight = {
  base: '193px',
  sm: '279px',
  md: '360px',
  lg: '405px',
  xl: '495px',
  '2xl': '540px',
};

const iframeBorderRadius = {
  base: '8px',
  sm: '10px',
  md: '10px',
  lg: '12px',
  xl: '12px',
  '2xl': '12px',
};

interface ResponsiveIframeProps {
  url: string;
  [x: string]: any;
}

const ResponsiveIframe = ({ url, ...rest }: ResponsiveIframeProps) => {
  return (
    <Box w={iframeWidth} h={iframeHeight} borderRadius={iframeBorderRadius} {...rest}>
      <iframe
        width="100%"
        height="100%"
        style={{ borderRadius: 'inherit' }}
        src={url}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer;
                autoplay;
                clipboard-write;
                encrypted-media;
                gyroscope;
                picture-in-picture;
                web-share"
        allowFullScreen
      ></iframe>
    </Box>
  );
};

export default ResponsiveIframe;
