import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Center, Flex, Text } from "@chakra-ui/react";
import React from "react";
import StoryblokClient from "storyblok-js-client";

interface IProp {
    faqsData: [{ question: any, answer: any }],
}

const Storyblok = new StoryblokClient({
    accessToken: process.env.GATSBY_STORYBLOCK_ACCESS_TOKEN,
    cache: {
        clear: "auto",
        type: "memory",
    },
});


export const FaqTitle = ({ text }) => {
    return (
        <AccordionButton p="16px 0">
            <Text flex="1" textAlign="left" as="h5">
                {text}
            </Text>
            <AccordionIcon fontSize="32px" />
        </AccordionButton>
    );
};

const Faqs: React.FC<IProp> = ({ faqsData }) => {
    return <Flex w="full" flexDir="column">
        <Text
            as="h2"
            textAlign="center"
            mb={{
                base: "24px",
                sm: "40px",
                md: "40px",
                lg: "80px",
                xl: "80px",
                "2xl": "80px"
            }}
        >
            FAQs
        </Text>
        <Accordion allowMultiple>
            {
                faqsData.map((faq, index) => {
                    return <AccordionItem key={index}>
                        <FaqTitle text={faq.question} />
                        <AccordionPanel px="0">
                            <Center
                                w="full"
                                flexDir="column"
                                className="cms-html"
                                dangerouslySetInnerHTML={{ __html: Storyblok.richTextResolver.render(faq.answer) }}
                            ></Center>
                        </AccordionPanel>
                    </AccordionItem>
                })
            }
        </Accordion>
    </Flex>
}

export default Faqs;