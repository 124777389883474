import { Box, Center, Flex, Grid, Text, theme } from '@chakra-ui/react'
import * as React from 'react'

interface IProp {
    data: {
        title: string,
        content: string
    },
    index: number
}

const rowGap = {
    base: "14px",
    sm: "14px",
    md: "16px",
    lg: "16px",
    xl: "16px",
    "2xl": "16px"
}

const WorkCard: React.FC<IProp> = ({ data, index }) => {
    return <Flex flexDir="column" p="24px" rowGap={rowGap} border="1px solid #dadee3" borderRadius="16px" w="full" h="full">
        <Center w="48px" h="48px" bg="#FBF0FF" borderRadius="12px" as="h4" color="#9F2AEC">
            {index}
        </Center>
        <Text as="h4">{data.title}</Text>
        <Text as="p" style={{ margin: 0 }}>{data.content}</Text>
    </Flex>
}

export default WorkCard