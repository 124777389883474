import Layout from "@/layout";
import {
  Center,
  Text,
  Link as ChakraLink,
  Button,
  Box,
  Grid,
  Flex,
  Accordion,
  AccordionItem,
  AccordionPanel,
  UnorderedList,
  ListItem,
  Link,
  GridItem,
} from "@chakra-ui/react";
import * as React from "react";
// import TemplateBG from "@/assets/images/TemplateBG.png";
import { WorkCardData } from "@/content/affiliate-program/Constants";
import WorkCard from "@/content/affiliate-program/WorkCard";
import { GatsbyLink } from "@/components/commons";

import Header from "@/components/head";
import { Script } from "gatsby";
import { FaqTitle } from "@/components/faqs";
import { ResponsiveIframe } from "@/components/video";

const { useRef, useState, useEffect, useMemo, memo } = React;

export interface IindexProps {}

const topLayout = (
  <Center
    bgPosition="100% 100%"
    bgSize="cover"
    bgRepeat="no-repeat"
    bgImage="https://a.storyblok.com/f/167495/x/886756942d/meetingtemplatebg.svg"
    p={{
      base: "118px 24px 56px",
      sm: "134px 40px 64px",
      md: "134px 64px 64px",
      lg: "150px 0px 80px",
      xl: "150px 0px 80px",
      "2xl": "190px 0px 120px",
    }}
    flexDir="column"
  >
    <Text as="h1" textAlign="center">
      Airgram Affiliate Program
    </Text>
    <Text as="p" textAlign="center">
      Join now free and get rewarded continuously for sharing Airgram with the
      world.
    </Text>
    <ChakraLink
      _hover={{
        textDecor: "none",
      }}
      lineHeight="52px"
      target="_blank"
      mt="24px"
      href="https://airgram-inc-1.getrewardful.com/signup"
    >
      <Button size="lg" colorScheme="brand">
        Get Started
      </Button>
    </ChakraLink>
    <ResponsiveIframe
      url="https://www.youtube.com/embed/6nQ2_BnVbf8"
      mt={{
        base: '24px',
        sm: '40px',
        md: '40px',
        lg: '40px',
        xl: '40px',
        '2xl': '40px',
      }}
    />
  </Center>
);

const marginBottom = {
  base: "40px",
  sm: "64px",
  md: "80px",
  lg: "120px",
  xl: "120px",
  "2xl": "160px",
};

export const Head = () => {
  return (
    <Header
      title="Affiliate Program | Airgram"
      description="Join our Affiliate program and earn commissions for sharing a better way of meeting!"
    />
  );
};

export const index: React.FC<IindexProps> = () => {
  const rewardfulLoadCallback = () => {
    const w = window,
      r = "rewardful";
    // @ts-ignore
    w._rwq = r;
    w[r] =
      w[r] ||
      function () {
        (w[r].q = w[r].q || []).push(arguments);
      };
  };
  return (
    <Layout topLayout={topLayout} isNewStyle={true}>
      <Script
        src="https://r.wdfl.co/rw.js"
        data-rewardful="07c05c"
        onLoad={rewardfulLoadCallback}
      />
      <Center flexDir="column">
        {/* What Are the Rewards */}
        <Center flexDir="column" mb={marginBottom}>
          <Text as="h2" textAlign="center">
            What Are the Rewards?
          </Text>
          <Center
            alignItems="start"
            flexDir={{
              base: "column",
              sm: "column",
              md: "row",
              lg: "row",
              xl: "row",
              "2xl": "row",
            }}
            mt={{
              base: "24px",
              sm: "40px",
              md: "64px",
              lg: "80px",
              xl: "80px",
              "2xl": "80px",
            }}
            columnGap={{
              base: "0px",
              sm: "0px",
              md: "40px",
              lg: "40px",
              xl: "180px",
              "2xl": "340px",
            }}
            px={{
              base: "0px",
              sm: "0px",
              md: "0px",
              lg: "0px",
              xl: "70px",
              "2xl": "150px",
            }}
          >
            {/* 20% */}
            <Center
              flex="1"
              flexDir="column"
              mb={{
                base: "24px",
                sm: "40px",
                md: "0px",
                lg: "0px",
                xl: "0px",
                "2xl": "0px",
              }}
            >
              <Center
                borderRadius={{
                  base: "32px",
                  sm: "40px",
                  md: "40px",
                  lg: "48px",
                  xl: "48px",
                  "2xl": "48px",
                }}
                h={{
                  base: "192px",
                  sm: "240px",
                  md: "240px",
                  lg: "270px",
                  xl: "270px",
                  "2xl": "270px",
                }}
                w="full"
                minW={{
                  base: "0px",
                  sm: "0px",
                  md: "324px",
                  lg: "436px",
                  xl: "480px",
                  "2xl": "480px",
                }}
                className="affiliate-program-card-bg-one"
                flexDir="column"
                color="#ffffff"
              >
                <Text
                  as="div"
                  textAlign="center"
                  color="inherit"
                  fontSize={{
                    base: "64px",
                    sm: "72px",
                    md: "72px",
                    lg: "80px",
                    xl: "80px",
                    "2xl": "80px",
                  }}
                  fontWeight="700"
                >
                  20%
                </Text>
                <Text as="h4" textAlign="center" color="inherit">
                  Commission Rate
                </Text>
              </Center>
              <Text
                as="p"
                px={{
                  base: "16px",
                  sm: "16px",
                  md: "16px",
                  lg: "24px",
                  xl: "24px",
                  "2xl": "24px",
                }}
              >
                You earn 20% of every transaction from users joining Airgram
                through your affiliate link.
              </Text>
            </Center>
            {/* Recurring Revenue */}
            <Center flex="1" flexDir="column">
              <Center
                borderRadius={{
                  base: "32px",
                  sm: "40px",
                  md: "40px",
                  lg: "48px",
                  xl: "48px",
                  "2xl": "48px",
                }}
                h={{
                  base: "192px",
                  sm: "240px",
                  md: "240px",
                  lg: "270px",
                  xl: "270px",
                  "2xl": "270px",
                }}
                w="full"
                minW={{
                  base: "0px",
                  sm: "0px",
                  md: "324px",
                  lg: "436px",
                  xl: "480px",
                  "2xl": "480px",
                }}
                className="affiliate-program-card-bg-two"
                flexDir="column"
                color="#ffffff"
              >
                <Text as="h2" textAlign="center" color="inherit">
                  Recurring Revenue
                </Text>
              </Center>
              <Text
                as="p"
                px={{
                  base: "16px",
                  sm: "16px",
                  md: "16px",
                  lg: "24px",
                  xl: "24px",
                  "2xl": "24px",
                }}
                w="full"
              >
                You will receive recurring commissions on all payments within
                the first 12 months for paying customers you refer to Airgram.
              </Text>
            </Center>
          </Center>
        </Center>
        {/* How it Works */}
        <Center flexDir="column" mb={marginBottom}>
          <Text as="h2" textAlign="center">
            How it Works
          </Text>
          <Text as="p" textAlign="center">
            Simply follow the steps below to get your affiliate link and start
            earning commissions!
          </Text>
          <Grid
            w="full"
            gridColumnGap="40px"
            gridRowGap={{
              base: "24px",
              sm: "40px",
            }}
            mt={{
              base: "6px",
              sm: "22px",
              md: "46px",
              lg: "62px",
              xl: "62px",
              "2xl": "62px",
            }}
            gridTemplateColumns={{
              base: "1fr",
              sm: "1fr",
              md: "1fr 1fr 1fr",
              lg: "1fr 1fr 1fr",
              xl: "1fr 1fr 1fr",
              "2xl": "1fr 1fr 1fr",
            }}
          >
            {WorkCardData.map((item, index) => {
              return (
                <GridItem key={item.title}>
                  <WorkCard data={item} index={index + 1} />
                </GridItem>
              );
            })}
          </Grid>
          <ChakraLink
            _hover={{
              textDecor: "none",
            }}
            lineHeight="52px"
            target="_blank"
            mt="80px"
            href="https://airgram-inc-1.getrewardful.com/signup"
          >
            <Button size="lg" colorScheme="brand">
              Become an Affiliate
            </Button>
          </ChakraLink>
        </Center>
        {/* FAQs */}
        <Flex w="full" flexDir="column" mb="160px">
          <Text as="h2" textAlign="center" mb="24px">
            FAQs
          </Text>

          <Accordion allowMultiple>
            {/* FAQ 1 */}
            <AccordionItem>
              <FaqTitle text="Is there any cost to becoming an affiliate?" />
              <AccordionPanel px="0">
                <Text>No, this is a totally free program. </Text>
              </AccordionPanel>
            </AccordionItem>

            {/* FAQ 2 */}
            <AccordionItem>
              <FaqTitle text="What are the conditions?" />
              <AccordionPanel px="0">
                <Box>
                  <Text>
                    Your referral will not qualify for revenues if the customer:
                  </Text>

                  <UnorderedList>
                    <ListItem>Is already an Airgram user</ListItem>
                    <ListItem>
                      Are self-referrals (referring yourself or your own
                      organization)
                    </ListItem>
                  </UnorderedList>
                </Box>
              </AccordionPanel>
            </AccordionItem>

            {/* FAQ 3 */}
            <AccordionItem>
              <FaqTitle text="How long does the cookie last?" />
              <AccordionPanel px="0">
                <Text>
                  30 days. If the user makes a purchase of Airgram within 30
                  days after clicking on your link, you will be rewarded with a
                  commission.
                </Text>
              </AccordionPanel>
            </AccordionItem>

            {/* FAQ 4 */}
            <AccordionItem>
              <FaqTitle text="When do I get paid?" />
              <AccordionPanel px="0">
                <Text>
                  When one of your leads becomes a paid subscriber, we wait 30
                  days to clear commissions based on that sale.
                </Text>
              </AccordionPanel>
            </AccordionItem>

            {/* FAQ 5 */}
            <AccordionItem>
              <FaqTitle text="How do I check my referral statistics?" />
              <AccordionPanel px="0">
                <Text>
                  After you have been accepted into the program, you will be
                  able to log in to your Affiliate account and see your sales
                  and commission results in the dashboard.
                </Text>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>

          <Box mt="12px">
            <Text
              as="span"
              color="#7d848e"
              width="fit-content"
              className="caption"
            >
              View the full
            </Text>
            <Text
              as={GatsbyLink}
              display="inline"
              _hover={{ textDecor: "underline" }}
              color="#9f2aec"
              to="/affiliate-terms"
            >
              <Text as="span" className="caption">
                {" "}
                affiliate program terms
              </Text>
            </Text>
            <Text
              as="span"
              color="#7d848e"
              width="fit-content"
              className="caption"
            >
              {" "}
              here.
            </Text>
          </Box>
        </Flex>
      </Center>
    </Layout>
  );
};
export default memo(index);
